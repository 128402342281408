<template>
  <div class="actionModule">
    <!-- $checkButtonPermissions(item.permissions) && -->
    <a
      href="javascript:;"
      v-for="(item, index) in actions"
      :key="index"
      @click="action(item.type, data)"
      v-if="($checkButtonPermissions(item.permissions) && (item.type === ActionConfig.look.ACTION_TYPE || isShow))"
      >{{ item.type !== ActionConfig.disabled.ACTION_TYPE ? item.title : status ? "启用" : "禁用" }}&nbsp;&nbsp;</a
    >
  </div>
</template>

<script>
import ActionConfig from "@/utils/actionConfig";
export default {
  name: "ActionModule",
  props: {
    actions: {
      type: Array,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    //启禁用状态
    status: {
      type: Boolean,
      default: false,
    },
    //适配账户权限(是否显示当前账户下的操作)
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    show: {
      get() {

      }
    }
  },
  data() {
    return {
      //操作配置
      ActionConfig: ActionConfig,
    };
  },
  methods: {
    action(actionType, data) {
      this.$emit("action", actionType, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.actionModule a {
  color: #1890ff;
}
.actionModule a:hover {
  color: #40a9ff;
}
</style>
