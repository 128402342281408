/**操作配置
 *
 * ACTION_TYPE: 操作类型(look: 查看, delete: 删除, edit: 删除, disabled: 启禁用, closeOrder: 关闭订单)
 * ACTION_TITLE: 按钮title
 */
export default {
  look: {
    ACTION_TYPE: "look",
    ACTION_TITLE: "查看"
  },
  delete: {
    ACTION_TYPE: "delete",
    ACTION_TITLE: "删除"
  },
  edit: {
    ACTION_TYPE: "edit",
    ACTION_TITLE: "编辑"
  },
  disabled: {
    ACTION_TYPE: "disabled",
  },
  closeOrder: {
    ACTION_TYPE: "closeOrder",
    ACTION_TITLE: "关闭订单"
  },
  addChild: {
    ACTION_TYPE: 'addChildren',
    ACTION_TITLE: '添加子级'
  }
}
