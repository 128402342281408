<template>
  <div class="lesseeManage">
    <ds-header title="租户管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="租户账号：" :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }">
        <a-input v-model="searchForm.userName" placeholder="请输入租户账号" />
      </a-form-item>
      <a-form-item label="租户名称：" :labelCol="{ span: 7 }" :wrapperCol="{ span: 17 }">
        <a-input v-model="searchForm.tenantName" placeholder="请输入租户名称" />
      </a-form-item>
      <a-form-item>
        <SearchResetModule @search="search" @resetSearchForm="resetSearchForm"></SearchResetModule>
      </a-form-item>
    </a-form>
    <div style="padding-top: 15px" v-if="$checkButtonPermissions($buttonPermissionsConfig.authManage.lesseeManage.add)">
      <ds-button :type="$DSBT.DS_ADD" title="新增租户" @click="addLessee" />
    </div>
    <div style="padding-top: 15px">
      <ds-table
        :rowKey="(record, index) => record.tenantId"
        :scroll="{ x: 1500 }"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        tableLayout="fixed"
        @change="pageChange"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <ActionModule :actions="actions" @action="action" :data="text" :status="text.status"></ActionModule>
        </span>
      </ds-table>
      <!-- <a-table
        :rowKey="(record, index) => record.tenantId"
        :scroll="{ x: 1500 }"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        tableLayout="fixed"
        @change="pageChange"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <ActionModule :actions="actions" @action="action" :data="text" :status="text.status"></ActionModule>
        </span>
      </a-table> -->
    </div>
    <ResetPassword :isShow.sync="isShow"></ResetPassword>
    <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import * as api from "@/api/authManage/lesseeManage";
import { REQUEST_CODE } from "@/constant/status";
import moment from "moment";
import ResetPassword from "./components/resetPassword";
import { mapState } from "vuex";
import PagingConfig from "@/utils/pagingConfig";
import ActionConfig from "@/utils/actionConfig";
import ActionModule from "@/components/actionModule";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import SearchResetModule from "@/components/searchResetModule";
export default {
  name: "LesseeManage",
  components: {
    ResetPassword,
    ActionModule,
    ConfirmBoxModule,
    SearchResetModule,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading,
    }),
  },
  data() {
    return {
      //是否显示重置密码框
      isShow: false,
      //搜索数据
      searchForm: {
        //租户账号
        userName: "",
        //租户名称
        tenantName: "",
      },
      //展示数据
      table: {
        //数据
        dataInfo: [],
        //title数据
        columns: [
          {
            title: "租户账号",
            dataIndex: "username",
            key: "username",
            width: "200px",
          },
          {
            title: "租户名称",
            dataIndex: "tenantName",
            key: "tenantName",
            width: "300px",
          },
          {
            title: "租户编号",
            dataIndex: "tenantNumber",
            key: "tenantNumber",
            width: "100px",
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
            width: "100px",
            customRender: (text) => {
              return text ? "禁用" : "启用";
            },
          },
          {
            title: "所属公司",
            dataIndex: "companyName",
            key: "companyName",
            width: "300px",
          },
          {
            title: "联系人",
            dataIndex: "contacts",
            key: "contacts",
            width: "100px",
          },
          {
            title: "联系电话",
            dataIndex: "phone",
            key: "phone",
            width: "150px",
          },
          {
            title: "创建人",
            dataIndex: "createName",
            key: "createName",
            width: "100px",
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            width: "150px",
            customRender: (text) => {
              if (text) {
                return moment(text).format("YYYY-MM-DD HH:mm");
              }
            },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: PagingConfig.defaultPage,
          pageSize: PagingConfig.defaultPageSize,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      //操作集合
      actions: [
        {
          type: ActionConfig.edit.ACTION_TYPE,
          title: "详情",
          permissions: this.$buttonPermissionsConfig.authManage.lesseeManage.detail
        },
        {
          type: ActionConfig.delete.ACTION_TYPE,
          title: ActionConfig.delete.ACTION_TITLE,
          permissions: this.$buttonPermissionsConfig.authManage.lesseeManage.delete
        },
        {
          type: ActionConfig.disabled.ACTION_TYPE,
          permissions: this.$buttonPermissionsConfig.authManage.lesseeManage.enable
        },
      ],
    };
  },
  methods: {
    //查询
    search() {
      this.refreshData();
    },
    //重置
    resetSearchForm() {
      this.searchForm = {
        userName: "",
        tenantName: "",
      };
      this.refreshData();
    },
    //添加租户
    addLessee() {
      this.$router.push({
        path: "/authManage/lesseeManage/addLesseeBaseInfo",
        query: {
          showType: "0",
        },
      });
    },
    //详情
    detail(text) {
      this.$router.push({
        path: "/authManage/lesseeManage/addLesseeBaseInfo",
        query: {
          showType: "2",
          tenantId: text.tenantId,
        },
      });
    },
    //删除
    async remove(text) {
      const confirmData = {
        title: "是否确认删除该租户?",
        content: "删除后租户信息及租户下所有账号将同步删除",
        type: ConfirmConfig.CONFIRM_TYPE_WARNING,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.deleteLessee(text.tenantId);
    },
    //重置密码
    resetPassword() {
      this.isShow = true;
    },
    //禁用
    async forbidden(text) {
      const title = text.status ? "启用" : "禁用";
      const confirmData = {
        title: `是否确认${title}该租户?`,
        content: `${title}后该租户对应权限及其子账户将被同步${title}，请谨慎操作！`,
        type: !text.status ? ConfirmConfig.CONFIRM_TYPE_WARNING : ConfirmConfig.CONFIRM_TYPE_SUCCESS,
      };
      await this.$refs.confirmBoxModule.show(confirmData);
      this.disableLessee(text.tenantId, !text.status);
    },
    //列表操作
    action(actionType, data) {
      if (actionType === ActionConfig.edit.ACTION_TYPE) {
        this.detail(data);
      } else if (actionType === ActionConfig.delete.ACTION_TYPE) {
        this.remove(data);
      } else if (actionType === ActionConfig.disabled.ACTION_TYPE) {
        this.forbidden(data);
      }
    },
    //分页操作
    pageChange(option) {
      this.refreshData(option.current, option.pageSize);
    },

    /**网络请求 */
    //获取租户列表
    async getLesseeList() {
      try {
        const result = await api.getLesseeList(
          this.searchForm,
          this.table.pagination.current,
          this.table.pagination.pageSize
        );
        this.table.dataInfo = result.data.list;
        this.table.pagination.total = result.data.total;
        this.dataTidy();
      } catch (error) {}
    },
    //删除租户
    async deleteLessee(tenantId) {
      try {
        const result = await api.deleteLessee(tenantId);
        if (result.code === REQUEST_CODE) {
          this.refreshData();
          this.$message.success(result.msg);
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error(error.msg);
      }
    },
    //禁用租户
    async disableLessee(tenantId, status) {
      try {
        const result = await api.disableLessee(tenantId, status ? 1 : 0);
        if (result.code === REQUEST_CODE) {
          this.refreshData();
          this.$message.success(result.msg);
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        this.$message.error(error.msg);
      }
    },

    //刷新数据
    refreshData(current = PagingConfig.defaultPage, pageSize = PagingConfig.defaultPageSize) {
      if (pageSize !== this.table.pagination.pageSize) {
        this.table.pagination.current = PagingConfig.defaultPage;
      } else {
        this.table.pagination.current = current;
      }
      this.table.pagination.pageSize = pageSize;
      this.getLesseeList();
    },

    /**数据处理 */
    dataTidy() {
      this.table.dataInfo.forEach((item) => {
        if (item.tenantInfo) {
          item.companyName = item.tenantInfo.companyName;
          item.contacts = item.tenantInfo.contacts;
          item.phone = item.tenantInfo.phone;
        }
      });
    },
  },
  async created() {
    this.refreshData();
    this.$checkTableActionIsShow(this.actions) && this.table.columns.push(this.$backTableAction({width: "200px"}));
  },
};
</script>

<style lang="scss" scoped>
</style>
