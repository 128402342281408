<template>
  <div class="searchResetModule">
    <ds-button :type="$DSBT.DS_SEARCH" @click="search" />
    <ds-button :type="$DSBT.DS_RESET" style="margin-left: 15px" @click="resetSearchForm" />
  </div>
</template>

<script>
export default {
  name: "SearchResetModule",
  methods: {
    search() {
      this.$emit("search");
    },
    resetSearchForm() {
      this.$emit("resetSearchForm");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
