<template>
  <div class="resetPassword">
    <a-modal v-model="visible" :title="'重置密码'" :footer="null" :maskClosable="false">
      <div>
        <a-form-model
          layout="horizontal"
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="密码：" prop="account">
            <a-input
              placeholder="请输入密码"
              :value="form.password"
              @input="(e) => (form.account = nameVerify(e))"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div style="text-align: center">
        <a-button @click="cancel"> 取消 </a-button>
        <a-button type="primary" style="margin-left: 20px" @click="save"> 保存 </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
   props: {
    isShow: {
      type: Boolean,
      default: false,
    }    
  },
  computed: {
    visible: {
      set() {
        this.resetData();
        this.$emit("update:isShow", false);
      },
      get() {
        return this.isShow;
      },
    },
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 19 },
      form: {
        //密码
        password: ""
      },
      rules: {
        password: {required: true, message:'请输入密码', trigger: 'blur'}
      }
    }
  },
  methods: {
    //保存
    save() {
      this.$emit("update:isShow", false);
      this.resetData();
    },
    //取消
    cancel() {
      this.$emit("update:isShow", false);
      this.resetData();
    },
    //重置数据
    resetData() {
      this.$refs[`ruleForm`].resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
