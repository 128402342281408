import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

//获取租户列表
export const getLesseeList = (searchData, page, size) => {
  const data = {
    ...searchData,
    page,
    size
  }
  return fetchApi(URL.GET_LESSEE_LIST, data, "post", "data");
};

//添加租户
export const addLessee = data => {
  return fetchApi(URL.ADD_LESSEE, data, "post", "data");
}

//查看租户详情
export const getLesseeDetail = tenantId => {
  const data = {
    tenantId
  }
  return fetchApi(URL.GET_LESSEE_DETAIL, data);
}
//编辑租户信息
export const editLesseeInfo = data => {
  return fetchApi(URL.EDIT_LESSEE_INFO, data, "post", "data");
}
//删除租户
export const deleteLessee = tenantId => {
  const data = {
    tenantId
  }
  return fetchApi(URL.DELETE_LESSEE, data);
}
//禁用租户
export const disableLessee = (tenantId, status) => {
  const data = {
    tenantId,
    status
  }
  return fetchApi(URL.DISABLE_LESSEE, data);
}
//重置密码
// export const disableLessee = (tenantId, status) => {
//   const data = {
//     tenantId,
//     status
//   }
//   return fetchApi(URL.DISABLE_LESSEE, data);
// }

export const getUserList = (data) => {
  return fetchApi(URL.USER_MESSAGE_LIST, data, "post", "data");
}

